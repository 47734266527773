import React from "react"

import Layout from "../components/layout"
import Meta from "../components/seo"


const Services = () => (
  <Layout>
    <Meta title="Contact Us"
          description="Let's begin the discussion on how we can help your brand today"
          url="https://www.greenvilleappleproducts.com/contact" 
          />
        <div className="container pad">
        <h1 className="xd-h1 mb-4">Contact Us</h1>

        <form method="post" className="w-full md:w-1/2" netlify-honeypot="bot-field" data-netlify="true" name="contact" action="/success">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="relative mb-4">
                <label for="name" htmlFor="name" className="leading-7 text-sm text-gray-600">Name</label>
                <input type="text" id="name" htmlFor="name" name="name" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            </div>
            <div className="relative mb-4">
                <label for="email" htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                <input type="email" id="email" htmlFor="email" name="email" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            </div>
            <div className="relative mb-4">
                <label for="phone" htmlFor="phone" className="leading-7 text-sm text-gray-600">Phone</label>
                <input type="text" id="phone" htmlFor="phone" name="phone" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            </div>
            <div class="relative mb-4">
                <label for="message" htmlFor="message" className="leading-7 text-sm text-gray-600">Message</label>
                <textarea id="message" name="message" htmlFor="message" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
            </div>
            <input type="submit" value="Submit" className="flex  mt-16 text-white bg-dark border-0 py-2 px-8 focus:outline-none hover:bg-deep rounded text-lg cursor-pointer"></input>
            </form>
        </div>
  </Layout>
)

export default Services
